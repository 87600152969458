.white-anteater-info-container {
  padding: 20px;
  background-color: #fff;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.white-anteater-info-container h1,
.white-anteater-info-container h2,
.white-anteater-info-container p {
  margin: 10px 0;
}

.white-anteater-info-container h1 {
  font-size: 2rem;
  color: #333;
}

.white-anteater-info-container h2 {
  font-size: 1.5rem;
  color: #555;
}

.white-anteater-info-container p {
  font-size: 1rem;
  color: #666;
}
