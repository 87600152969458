.game-card {
  margin: 10px;
  border: 1px solid black;
  padding: 10px;
  width: 250px;
  transition: transform 0.3s, background-color 0.3s;
}

.game-card:hover {
  transform: scale(1.1);
  background-color: #f0f0f0;
}

.game-image {
  width: 100%;
  height: auto;
}

.add-game-input {
  margin: 10px 0;
  padding: 5px;
}

.format-info {
  font-style: italic;
}