.App-header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.main-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }
}
