.navbar {
  background-color: #333;
  overflow: hidden;
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navbar-item {
  padding: 14px 20px;
}

.navbar-link {
  color: white;
  text-align: center;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s, transform 0.3s;
}

.navbar-link:hover {
  background-color: #575757;
  transform: scale(1.1);
}