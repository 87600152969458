body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #fafafa;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Fira Code', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3 {
  font-weight: 700;
}

p {
  font-weight: 300;
}

.container {
  width: 85%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.header, .footer {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.main-content {
  padding: 20px;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}