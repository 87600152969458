.winner {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.game-board {
  border: 1px solid black;
  padding: 10px;
  margin: 10px 0;
}

.player {
  border: 1px solid black;
  padding: 20px;
}

.scores {
  list-style-type: none;
  padding: 0;
}

.scores li {
  margin: 5px 0;
}