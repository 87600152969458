.tetris-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.canvas-style {
  width: 100%;
  height: auto;
  max-width: 800px;
  max-height: 600px;
}